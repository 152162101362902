// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-2021-launch-of-beta-js": () => import("./../../../src/pages/blog/2021/launch-of-beta.js" /* webpackChunkName: "component---src-pages-blog-2021-launch-of-beta-js" */),
  "component---src-pages-blog-2023-general-availability-js": () => import("./../../../src/pages/blog/2023/general-availability.js" /* webpackChunkName: "component---src-pages-blog-2023-general-availability-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-app-js": () => import("./../../../src/pages/privacy/app.js" /* webpackChunkName: "component---src-pages-privacy-app-js" */),
  "component---src-pages-privacy-website-js": () => import("./../../../src/pages/privacy/website.js" /* webpackChunkName: "component---src-pages-privacy-website-js" */)
}

